@import '../../assets/scss/_partials/required';
@import '../../assets/scss/_mixins/fontSize';

.tags {
    margin: $smallSpacing 0;
    display: flex;
    flex-wrap: wrap;

    .tag {
        @include fontSize(16px);
        flex: 0 0 auto;
        color: $whiteA87;
        border-radius: 6px;
        margin: $smallSpacing;
        padding: 4px 10px;
        font-weight: 500;
        transition: all 0.2s linear;
        border: 1px solid $whiteA87;
        background: transparent;
        cursor: default;

        &.link {
            background: rgba(255, 255, 255, 0.24);
            cursor: pointer;
            &:hover {
                background: rgba(255, 255, 255, 0.54);
            }
        }

        a {
            text-decoration: none;
            color: $whiteA87;

            &:hover {
                text-decoration: none;
            }
        }
    }
}