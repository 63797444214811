$baseFontSize: 16px;

@function calculateRem($size) {
    $remSize: $size / $baseFontSize;
    @return #{$remSize}rem;
}

@mixin fontSize($size) {
    font-size: $size; //Fallback in px
    font-size: calculateRem($size);
}