@import '../../assets/scss/_partials/required';
@import '../../assets/scss/_mixins/fontSize';
@import '../../assets/scss/icons';
@import '../../assets/scss/helpers';

.jv_modal {
    position: fixed;
    top: 16px;
    right: 16px;
    bottom: 16px;
    left: 16px;
    max-width: 1200px;
    margin: 0 auto;
    opacity: 0;
    z-index: -1;
    transition: all 0.3s ease;
    background: rgba(0, 0, 0, 0.92);
    box-sizing: border-box;
    padding: $largeSpacing;
    border-radius: 8px;
    backdrop-filter: blur(0px);
    display: grid !important;
    grid-template-columns: 100%;
    grid-template-rows: auto 50px 1fr;
    border: 1px solid rgba(255, 255, 255, 0.12);

    &.show {
        z-index: 6;
        opacity: 1;
        backdrop-filter: blur(3px);
    }

    .modalHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $smallSpacing;
        padding-bottom: $smallSpacing;
        border-bottom: 1px solid rgba(227, 251, 255, 0.75);
        h4 {
            @include fontSize(32px);
            font-weight: 100;
            text-transform: none;
            flex: 1 0 0;
            margin: 0;
        }
        .closeButton {
            flex: 32px 0 0;
            background-color: transparent;
            border: 0;
            width: 32px;
            height: 32px;
            border-radius: 20px;
            transition: all 0.1s ease-in-out;
            margin: 0;
            z-index: 2;
    
            &:hover {
                background-color: rgba(255, 255, 255, 0.1);
            }
        }
    }

    h5 {
        @include fontSize(18px);
        font-weight: 500;
        text-transform: uppercase;
        color: $whiteA87;
    }

    .modalBody {
        overflow: hidden;
        overflow-y: auto;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
        &::-webkit-scrollbar-thumb {
            background: rgba(255, 255, 255, 0.24);
            border-radius: 10px;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
    }
}