@import '../../assets/scss/_partials/required';
@import '../../assets/scss/_mixins/fontSize';

#About {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    h2 {
        @include fontSize(48px);
        font-weight: 400;
        text-transform: none;
        line-height: 32px;
        transition: all 0.6s ease-in-out;
        opacity: 0;
        transform: translate(-200px, 0px);
    }
    h3 {
        @include fontSize(28px);
        line-height: 28px;
        font-weight: 300;
        text-transform: none;
        margin: 32px 0 24px;
        transition: all 0.6s ease-in-out;
        opacity: 0;
        transform: translate(200px, 0px);
    }
    h4 {
        @include fontSize(24px);
        line-height: 20px;
        font-weight: 100;
        text-transform: uppercase;
        margin: 0 0 16px;
    }
    .aboutTags {
        transition: all 0.6s ease-in-out;
        opacity: 0;
        transform: translate(-200px, 0px);
    }
    &.active {
        h2, h3, .aboutTags {
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }

    /* Small screen */
    @media (max-width: 550px) {
        h2 {
            @include fontSize(40px);
        }
        h3 {
            @include fontSize(22px);
            margin-top: $largeSpacing;
        }
    }

    /* small screen */

    @media (max-width: 480px) {
        h2 {
            @include fontSize(32px);
        }
        h3 {
            @include fontSize(22px);
            margin-top: $mediumSpacing;
        }
    }

    /* iphone x & pixel */

    @media (max-width: 411px) {
        h2 {
            @include fontSize(28px);
        }
        h3 {
            @include fontSize(18px);
            margin-top: $smallSpacing;
        }
    }

    /* galaxy fold */

    @media (max-width: 280px) {
        h2 {
            @include fontSize(20px);
        }
        h3 {
            @include fontSize(16px);
        }
    }
}

.aboutContainer {
    display: grid;
    padding: 24px;
    margin-top: 60px;
    grid-gap: 24px;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
}

.aboutTags {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    margin: 0;
    padding: 0 16px 8px;
    overflow: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.24);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    .card {
        flex: 1 0 400px;
        background: rgba(255, 255, 255, 0.08);
        padding: 16px 16px 0;
        margin: 0 24px 0 0;
        border-radius: 4px;
    }
    /* iphone x & pixel */

    @media (max-width: 411px) {
        .card {
            flex: 1 0 400px;
        }
    }
}