@import '../../assets/scss/_partials/required';
@import '../../assets/scss/_mixins/fontSize';

#Home {
    z-index: 5;
    
    h1 {
        @include fontSize(44px);
        width: 440px;
        white-space: nowrap;
        line-height: 32px;
        margin: 0;
        text-transform: uppercase;
        font-weight: 100;
        transition: all 0.6s ease-in-out;
        text-align: center;
        transform: translate(calc(50vw - 220px), calc(50vh + 28px));
    }

    h2 {
        @include fontSize(16px);
        width: 226px;
        white-space: nowrap;
        transition: all 0.6s ease-in-out;
        font-weight: 500;
        text-align: center;
        opacity: 1;
        transform: translate(calc(50vw - 115px), calc(50vh + 50px));
    }

    #SubText2 {
        text-decoration: underline;
    }

    #ScrollIndicator {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 20px;
        transform: translate(calc(50vw - 10px), calc(50vh + 140px));
        opacity: 0;
        transition: all 0.6s ease-in-out;
        .angleDown {
            @include fontSize(24px);
            transform: translateY(0px);
            &:first-of-type {
                animation: moveArrow 3s ease-in-out infinite;
            }
            
            &:last-of-type {
                transform: translateY(-14px);
                animation: moveArrow2 3s ease-in-out infinite;
                animation-delay: 0.15s;
            }
        }
        &.show {
            opacity: 1; 
        }
    }

    #Source {
        text-align: center;
        opacity: 0;
        display: none;
        width: 100px;
        margin: 0;
        transition: all 0.6s ease-in-out;
        transform: translate(calc(50vw - 50px), calc(50vh + 70px));

        a {
            font-size: 1.125rem;
            padding: $smallSpacing;
            color: rgba(227, 251, 255, 0.75);
            cursor: pointer;
        }
    }

    .itchIcon {
        color: rgba(227, 251, 255, 0.75);
        height: 1.125rem;
        filter: url(#itchGlow);
        position: relative;
        top: 3px;
    }

    &.notActive {
        h1 {
            opacity: 0;
            transform: translate(calc(50vw - 220px), calc(50vh - 28px));
        }

        h2 {
            opacity: 0;
            transform: translate(calc(50vw - 113px), calc(50vh - 50px));
        }

        #Source {
            opacity: 0 !important;
            transform: translate(calc(50vw - 50px), calc(50vh - 70px));
        }

        #ScrollIndicator {
            opacity: 0;
            transform: translate(calc(50vw - 10px), calc(50vh - 140px));
        }
    }

    /* iphone x & pixel */

    @media (max-width: 411px) {
        h1 {
            @include fontSize(32px);
        }
    
        // h2 {
        //     @include fontSize(16px);
        // }
    }

    /* galaxy fold */

    @media (max-width: 280px) {
        h1 {
            @include fontSize(24px);
        }
    
        // h2 {
        //     @include fontSize(16px);
        // }
    }

    /* Desktop */

    @media (min-width: 992px) {
        h1 {
            @include fontSize(48px);
        }
    
        h2 {
            @include fontSize(16px);
        }
    }
}

@keyframes moveArrow {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes moveArrow2 {
    0% {
        transform: translateY(-14px);
    }
    50% {
        transform: translateY(-24px);
    }
    100% {
        transform: translateY(-14px);
    }
}