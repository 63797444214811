//Primary colors
$blueA75: rgba(227, 251, 255, 0.75);
$text-color: #e3fbff;
$text-color2: rgba(227, 251, 255, 0.75);
$text-shadow: rgba(255, 255, 255, 0.75);
$bkg-color: #000000;
$dark-blue: #0b3954;

$blackA24: rgba(0, 0, 0, 0.24);
$blackA87: rgba(0, 0, 0, 0.87);
$whiteA24: rgba(255, 255, 255, 0.24);
$whiteA64: rgba(255, 255, 255, 0.64);
$whiteA87: rgba(255, 255, 255, 0.87);