@import '../../assets/scss/_partials/required.scss';
@import '../../assets/scss/_mixins/fontSize';

#logo {
    display: block;
    transition: all 0.6s ease-in-out;
    transform: translate(calc(50vw - 104px), calc(50vh - 80px)) scale(1);
    position: fixed;
    z-index: 4;

    svg {
        width: 208px;
        height: 160px;
        transition: all 0.6s ease-in-out;
        .pathgroup {
            .path {
                fill-opacity: 0.0;
                stroke-dasharray: 1000;
                stroke-dashoffset: 1000;
                animation: dash 0.5s ease-in-out forwards;
                animation-delay: 0.1s;
                fill: rgba(227, 251, 255, 0.54);

                &.fill {
                    stroke-dashoffset: 0;
                    fill-opacity: 0.0;
                    animation: fill 0.5s ease-in-out forwards;
                }
            }
        }
    }

    &.home {
        transform: translate(calc(50vw - 104px), calc(50vh - 140px));
    }
    
    &.header {
        transform: translate(-56px, -56px) scale(0.25);
    }
}

#nav {
    width: 100%;
    position: fixed;
    top: 0;
    background: rgba(0, 0, 0, 0.54);
    backdrop-filter: blur(3px);
    z-index: 4;
    opacity: 0;
    transition: all 0.6s ease-in-out;
    transform: translateY(-40px);
    padding: $mediumSpacing 0;
    box-sizing: border-box;
    &.show {
        opacity: 1;
        transform: translateY(0px);
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        transition: all 0.6s ease-in-out;
        li {
            text-align: center;
            a {
                display: block;
                margin: 0 auto;
                padding: 0 $mediumSpacing;
                transition: all 0.1s ease;
                font-weight: 200;
                text-transform: uppercase;
                transform: scale(1);
                &:hover {
                    color: rgba(255, 255, 255, 1.0);
                    text-decoration: none;
                }
                &.active {
                    color: rgba(255, 255, 255, 1.0);
                    font-weight: 500;
                    transform: scale(1.1);
                }
            }
        }
    }

    #iconNav {
        width: 40px;
        height: 45px;
        position: absolute;
        right: 24px;
        top: -45px;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;
        opacity: 0;
        span {
            display: block;
            position: absolute;
            height: 2px;
            width: 100%;
            background: rgba(227, 251, 255, 0.75);;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .25s ease-in-out;
            -moz-transition: .25s ease-in-out;
            -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out;
        }
        span:nth-child(1) {
            top: 0px;
        }
        span:nth-child(2),
        span:nth-child(3) {
            top: 14px;
        }
        span:nth-child(4) {
            top: 27px;
        }
        &.open {
            span:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
            span:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
                }
            span:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            span:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }
    }

    @media (max-width: 600px) { 
        & {
            height: 54px;
            &.open {
                height: 100vh;
            }
        }
        #iconNav {
            top: 12px;
            opacity: 1;
            z-index: 2;
        }
        ul {
            flex-direction: column;
            position: absolute;
            top: -100vh;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100vh;
            opacity: 0;
            li a {
                padding: $largeSpacing 0;
            }
            &.mobileOpen {
                opacity: 1;
                top: 0;
            }
        }
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fill {
    to {
        fill-opacity: 1;
    }
}