@import '../../assets/scss/_partials/required';
@import '../../assets/scss/_mixins/fontSize';

#Work {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    h2 {
        @include fontSize(48px);
        font-weight: 400;
        text-transform: none;
        line-height: 32px;
        transition: all 0.6s ease-in-out;
        opacity: 0;
        transform: translate(-200px, 0px);
    }
    h3 {
        @include fontSize(28px);
        line-height: 28px;
        font-weight: 300;
        text-transform: none;
        margin: 32px 0 24px;
        transition: all 0.6s ease-in-out;
        opacity: 0;
        transform: translate(200px, 0px);
    }
    h4 {
        @include fontSize(24px);
        line-height: 28px;
        font-weight: 100;
        text-transform: none;
        color: $whiteA87;
        margin: 0;
    }
    h5 {
        @include fontSize(16px);
        line-height: 16px;
        font-weight: 500;
        text-transform: uppercase;
        margin: $smallSpacing 0 $mediumSpacing;
    }
    .workItems {
        transition: all 0.6s ease-in-out;
        opacity: 0;
        transform: translate(-200px, 0px);
    }
    .carousel.carousel-slider {
        max-width: 916px;
        margin: 0 auto;
    }
    a {
        text-decoration: underline;
    }
    &.active {
        h2, h3, .workItems {
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }

    .workContainer {
        display: grid;
        padding: 24px;
        margin-top: 60px;
        grid-gap: 24px;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;
    }

    /* Small screen */
    @media (max-width: 550px) {
        h2 {
            @include fontSize(40px);
        }
        h3 {
            @include fontSize(22px);
            margin-top: $largeSpacing;
        }
    }

    /* small screen */

    @media (max-width: 480px) {
        h2 {
            @include fontSize(32px);
        }
        h3 {
            @include fontSize(22px);
            margin-top: $mediumSpacing;
        }
    }

    /* iphone x & pixel */

    @media (max-width: 411px) {
        h2 {
            @include fontSize(28px);
        }
        h3 {
            @include fontSize(18px);
            margin-top: $smallSpacing;
        }
    }

    /* galaxy fold */

    @media (max-width: 280px) {
        h2 {
            @include fontSize(20px);
        }
        h3 {
            @include fontSize(16px);
        }
    }
}

.workItems {
    display: flex;
    flex-wrap: nowrap;
    margin: 0;
    padding: 8px 16px;
    overflow: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.24);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    .card {
        flex: 1 0 300px;
        background: rgba(255, 255, 255, 0.08);
        padding: 16px 16px 0;
        margin: 0 24px 0 0;
        border-radius: 4px;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        transform: scale(1);
        .cover {
            width: 100%;
            margin-bottom: $mediumSpacing;
        }
        .carousel-root {
            margin-bottom: $mediumSpacing;
        }
        p {
            margin: $smallSpacing 0 $mediumSpacing;
            white-space: nowrap;
            overflow: hidden;
            width: 300px;
            text-overflow: ellipsis;
        }
        &:hover {
            background: rgba(0, 0, 0, 0.54);
            transform: scale(1.02);
        }
    }
}

.jv_modal {
    .workModalContent {
        display: grid;
        grid-gap: 24px;
        grid-template-columns: 50% 50%;
        grid-template-rows: 1fr;
        .workDescription {
            order: 1;
        }
        .workScreenshot {
            order: 2;
            text-align: center;
            padding: $largeSpacing;
            .cover {
                width: 100%;
                max-width: 400px;
            }
        }
        a {
            @include fontSize(24px);
            display: inline-block;
            text-decoration: underline;
            font-weight: 500;
            margin-bottom: $smallSpacing;
        }
        p {
            @include fontSize(18px);
            font-weight: 500;
            margin-bottom: $largeSpacing;
        }
        h6 {
            @include fontSize(16px);
            font-weight: 500;
            text-transform: uppercase;
            color: $whiteA87;
        }
        ul {
            li {
                @include fontSize(16px);
                font-weight: 300;
                // color: $whiteA87;
            }
        }
        .tags {
            margin-top: $mediumSpacing;
        }
    }
    /* Small screen */
    @media (max-width: 980px) {
        .workModalContent {
            grid-template-columns: 100%;
            grid-template-rows: auto 1fr;
            .workDescription {
                order: 2;
            }
            .workScreenshot {
                order: 1;
            }
        }
    }
}