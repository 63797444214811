@import '_partials/required';
@import '_mixins/fontSize';
@import 'icons';
@import 'helpers';

html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background: $bkg-color;
    font-size: $baseFontSize;
    color: rgba(227, 251, 255, 0.75);
    overflow: hidden;
}

a {
    color: rgba(227, 251, 255, 0.75);
}

h2 {
    @include fontSize(18px);
    line-height: 20px;
    margin: 0;
    text-transform: uppercase;
    font-weight: $semiBold;
}

h3 {
    @include fontSize(16px);
    line-height: 20px;
    margin: 0;
    font-weight: normal;
    color: $whiteA87;
}

p {
    @include fontSize(16px);
    font-weight: 300;
}

.headingBorder {
    border-bottom: 1px dashed $whiteA87;
    padding-bottom: $smallSpacing;
}

#bkgFilter {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.24);
    backdrop-filter: blur(0px);
    z-index: 2;
    transition: all 0.5s ease-in-out;
    &.blur {
        backdrop-filter: blur(3px);
    }
}

.page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    padding: $mediumSpacing 0 0;
    overflow: hidden;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.24);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    .section {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100vh;
        scroll-snap-align: start;
        &.active {
            z-index: 4;
        }
    }
    
    .customScrollbarContainer { 
        & > div {
            &:first-child {
                overflow: visible !important;
                scroll-snap-type: y mandatory;
                scroll-behavior: smooth;
                -webkit-overflow-scrolling: touch;
            }
            &:last-child {
                display: none;
            }
        }
        .thumbHorizontal {
            display: none !important;
        }
    }

    &.scroll {
        overflow-y: auto;
    }

    #caret {
        width: 10px;
        text-decoration: underline;
        visibility: visible;
    }

    #IntroContent {
        margin-top: 20px;
        text-align: center;

        #Ninja {
            font-size: 2.75rem;
            display: none;
        }
    }

    #ColCenter {
        transition: width 0.5s ease-in-out;
    }

    &.lab {
        width: 100%;
        height: 100%;
        overflow: hidden;

        & > .customScrollbarContainer {
            & > div > .thumbVertical, & > div > .thumbHorizontal {
                opacity: 0 !important;
            }
        }
    }
}

#ColLeft.center {
    position: absolute;
    left: -webkit-calc(50% - (104px/2));
    left: -moz-calc(50% - (104px/2));
    left: calc(50% - (104px/2));
    top: -webkit-calc(50% - (80px/2));
    top: -moz-calc(50% - (80px/2));
    top: calc(50% - (80px/2));

    #logo {
        margin: 0;
        transform: translate(-40px, -40px);
    }

    &.shift {
        animation: shift 1s ease-in-out forwards;

        #logo {
            margin: 0;
            animation: marginShift 1s ease-in-out forwards;
            transform: translate(0, 0);
        }
    }
}

.labContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: -1;
    transition: opacity 0.5s ease-in-out;

    .closeButton {
        position: absolute;
        top: 16px;
        right: 16px;
        background-color: transparent;
        border: 0;
        width: 32px;
        height: 32px;
        border-radius: 20px;
        transition: background-color 0.1s ease-in-out;
        padding: 1px 0 0 0;
        margin: 0;
        z-index: 2;

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }

    .content {
        display: none;
        overflow: hidden;
        overflow-y: auto;
        height: 100%;

        ul {
            padding: 0;
            margin: $largeSpacing 0 0 $largeSpacing;
            list-style-type: none;

            li {
                width: 225px;
                height: 150px;
                float: left;
                display: block;
                margin: $smallSpacing $mediumSpacing;
                background: rgba(255, 255, 255, 0.1);
                text-align: center;

                a {
                    width: 100%;
                    height: 100%;
                    color: $whiteA87;
                    text-shadow: none;
                    display: flex;
                    flex-direction: column;

                    .imgContainer {
                        width: 100%;
                        flex-grow: 1;

                        img {
                            width: 100%;
                        }
                    }

                    span {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 41px;
                    }
                }
            }
        }
    }

    &.show {
        z-index: 1;
        opacity: 1;

        .content {
            display: block;
        }
    }
}

.customScrollbarContainer {
    .thumbVertical {
        width: 4px;
        background-color: $whiteA24;
        border-radius: 20px;
        opacity: 0;
        transition: opacity 300ms;
        z-index: 4;

        &:hover,
        &:active {
            width: 12px !important;
            left: -4px;
        }
    }

    .thumbHorizontal {
        height: 4px;
        bottom: 0;
        background-color: $whiteA24;
        border-radius: 20px;
        opacity: 0;
        transition: opacity 300ms;
        z-index: 4;

        &:hover,
        &:active {
            height: 12px !important;
            bottom: 4px;
        }
    }

    &:hover,
    &:active {

        .thumbVertical,
        .thumbHorizontal {
            opacity: 1 !important;
        }
    }
}

@keyframes shift {
    to {
        top: 30px;
    }
}

@keyframes marginShift {
    to {
        margin: -10px 0 -10px 12px;
    }
}


/* Desktop */

@media (min-width: 992px) {
    .page {

        #IntroContent {
            margin-top: 0;
            max-width: 920px;
            margin: 0 auto;
        }
    }

    @keyframes shift {
        to {
            top: 30px;
            left: 30px;
        }
    }

    @keyframes marginShift {
        to {
            margin: -10px 0 -10px -12px;
        }
    }
}