@import '../../assets/scss/_partials/required';
@import '../../assets/scss/_mixins/fontSize';

#Contact {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    h2 {
        @include fontSize(48px);
        font-weight: 400;
        text-transform: none;
        line-height: 32px;
        transition: all 0.6s ease-in-out;
        opacity: 0;
        transform: translate(-200px, 0px);
    }
    h3 {
        @include fontSize(28px);
        line-height: 28px;
        font-weight: 300;
        text-transform: none;
        margin: 32px 0 24px;
        transition: all 0.6s ease-in-out;
        opacity: 0;
        &.contactText1 {
            transform: translate(200px, 0px);
        }
        &.contactText2 {
            transform: translate(-200px, 0px);
        }
    }
    a {
        text-decoration: underline;
    }
    &.active {
        h2, h3 {
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }

    .contactContainer {
        padding: 24px;
        margin-top: 60px;
        width: 100%;
    }

    /* Small screen */
    @media (max-width: 550px) {
        h2 {
            @include fontSize(40px);
        }
        h3 {
            @include fontSize(22px);
            margin-top: $largeSpacing;
        }
    }

    /* small screen */

    @media (max-width: 480px) {
        h2 {
            @include fontSize(32px);
        }
        h3 {
            @include fontSize(22px);
            margin-top: $mediumSpacing;
        }
    }

    /* iphone x & pixel */

    @media (max-width: 411px) {
        h2 {
            @include fontSize(28px);
        }
        h3 {
            @include fontSize(18px);
            margin-top: $smallSpacing;
        }
    }

    /* galaxy fold */

    @media (max-width: 280px) {
        h2 {
            @include fontSize(20px);
        }
        h3 {
            @include fontSize(16px);
        }
    }
}